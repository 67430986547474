require("@rails/ujs").start();
require("turbolinks").start();
require("jquery");
require("jquery-validation");

import 'javascripts/contact-form';
import 'javascripts/cookies-notification';
import 'javascripts/editor';
import 'javascripts/error-page';
import 'javascripts/fixed-block';
import 'javascripts/header-section';
import 'javascripts/ie-notification';
import 'javascripts/menu';
import 'javascripts/os-style-fixes';
import 'javascripts/projects-section';
import 'javascripts/reviews-section';
import 'javascripts/team-section';
import 'javascripts/technologies-section';
import "javascripts/map";

import 'javascripts/slick';

import "stylesheets/home";
