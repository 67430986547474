$(document).on("turbolinks:load", function () {
    const data = [
        {country: 'Switzerland', count: 15,  x: 49,  y: 40.8 },
        {country: 'Israel',      count: 14,  x: 55.5,  y: 47 },
        {country: 'China',       count: 1,  x: 74.5,  y: 51.5 },
        {country: 'Poland',      count: 6,  x: 52.5,  y: 38.5 },
        {country: 'Holland',     count: 8,  x: 48.5,  y: 37 },
        {country: 'Belgium',     count: 3,  x: 47.5,  y: 39 },
        {country: 'Germany',     count: 3,  x: 50.5,  y: 37.5 },
        {country: 'Argentina',   count: 1,  x: 30,  y: 71 },
        {country: 'Washington',  count: 1,  x: 18,  y: 41 },
        {country: 'California',  count: 6,  x: 16.5,  y: 44 },
        {country: 'Arizona',     count: 6,  x: 21,  y: 45 },
        {country: 'New York',    count: 2,  x: 28.5,  y: 43.5 },
        {country: 'Illinois',    count: 1,  x: 25,  y: 43 },
        {country: 'Massachusetts', count: 2,  x: 30,  y: 42 }
    ]

    data.forEach((point, index) => {
        $('.js-map-block').append(
            '<div class="pin ' + checkSize(point.count) + ' tooltip" ' +
            'style="top: ' + point.y + '%; left: ' + point.x + '%;">' +
            '<span class="tooltiptext text-12 white-text bold-text">'+ point.country + ' - ' + point.count +'</span>' +
            '<div class="animated-block" style="animation-delay: ' + index/10 + 's;"></div></div>');
    })
});

function checkSize(count) {
    switch(count) {
        case 1:
            return  'tiny';
        case 2:
        case 3:
            return 'small';
        case 4:
        case 5:
        case 6:
            return 'medium';
        default:
            return 'big';
    }
}